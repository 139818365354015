import { compose } from "recompose";
import { connect } from "react-redux";

import { selectCollection } from "../selectors/collections";
import {
  selectPendingCollectionName,
  selectPendingCollectionDescription,
  selectPendingCollectionPhase,
  selectPendingCollectionSymbol,
  selectPendingCollectionMetadata,
  selectPendingCollectionThumbnail,
} from "../selectors/collectionsFactory";
import CollectionCard from "../components/CollectionCard";

const mapStateToProps = (state, props) => ({
  collection: selectCollection(state, props),
  metadata: selectPendingCollectionMetadata(state, props),
  name: selectPendingCollectionName(state, props),
  description: selectPendingCollectionDescription(state, props),
  phase: selectPendingCollectionPhase(state, props),
  symbol: selectPendingCollectionSymbol(state, props),
  thumbnail: selectPendingCollectionThumbnail(state, props),
});

export default compose(connect(mapStateToProps))(CollectionCard);
