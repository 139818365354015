import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Box } from "smooth-ui";

const StyledHeader = styled(Box)`
  padding: 25px 50px;
`;

const rand = num => Math.floor(Math.random() * (num - 1) + 0);

const fonts = [
  "Monoton",
  "Shrikhand",
  "Poppins",
  "Fjalla One",
  "Pacifico",
  "Lobster",
  "Acme",
];

const NiftyNavLink = styled(NavLink)`
  text-decoration: none;
`;

const Logo = styled.h1`
  background-color: #e3241e;
  color: white;
  font-size: 1.5em;
  font-weight: 500;
  padding: 15px;
  letter-spacing: 0.12em;
  font-family: ${fonts[rand(fonts.length - 1)]}, sans-serif;
  transform: perspective(409px) rotateY(10deg);
  transition: transform 0.1s;

  &:hover {
    transform: perspective(409px) rotateY(-15deg);
  }
`;

const HeaderComponent = ({ right }) => (
  <StyledHeader alignItems="center" justifyContent="space-between">
    <NiftyNavLink to={`/`}>
      <Logo>Nifty.Supply</Logo>
    </NiftyNavLink>
    {right}
  </StyledHeader>
);

export default HeaderComponent;
