import React from "react";

import {
  Card,
  CardContent,
  CardCover,
  CardFooter,
  CardFooterNavLink,
  CardName as CardHeader,
  CardDescription as SubHeader,
  CardPending,
} from "../ui";

function getPhaseText(phase) {
  switch (phase) {
    case "UPLOADING":
      return "We're uploading your piece 🤘";
    case "SENDING":
      return "Please confirm the transaction using metamask 😎";
    case "MINING":
      return "Waiting for your transaction to be mined 🎉";
    default:
      return "Loading";
  }
}

export default props => {
  const { thumbnail, name, description, phase, isLoading, certificate } = props;
  return (
    <Card>
      <CardCover imageUri={thumbnail} />
      <CardContent>
        <CardHeader>{name}</CardHeader>
        <SubHeader>
          {!certificate
            ? description || "Loading..."
            : `"${name}" - ${certificate.get(
                "creationDate"
              )} - ${certificate.get("medium")}
        ${certificate.get("materials")} - ${certificate.get("dimensions")}`}
        </SubHeader>
      </CardContent>
      {isLoading && <CardPending>{getPhaseText(phase)}</CardPending>}
    </Card>
  );
};
