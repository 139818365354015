import { all, fork } from "redux-saga/effects";
import { sagas as web3Sagas } from "redux-saga-web3";

import { saga as Nifty } from "../contracts/nifty";
import { saga as NiftyFactory } from "../contracts/niftyFactory";

export default function* root() {
  yield all([
    ...Object.values(web3Sagas).map(saga => fork(saga)),
    fork(Nifty),
    fork(NiftyFactory),
  ]);
}
