import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import {
  Card,
  CardContent,
  CardCover,
  CardDescription,
  CardPending,
  CardName,
  Cover,
} from "../ui";

const NavLinkCard = Card.withComponent(NavLink);

function getPhaseText(phase) {
  switch (phase) {
    case "UPLOADING":
      return "We're uploading your collection 🤘";
    case "SENDING":
      return "Please confirm the transaction using metamask 😎";
    case "MINING":
      return "Waiting for your transaction to be mined 🎉";
    default:
      return "Loading";
  }
}

export default props => {
  const {
    thumbnail,
    name,
    description,
    symbol,
    address,
    isLoading,
    phase,
  } = props;
  if (address) {
    return (
      <NavLinkCard to={`/collections/${address}`}>
        <CardCover imageUri={thumbnail} />
        <CardContent>
          <CardName symbol={symbol}>{name}</CardName>
          <CardDescription>{description}</CardDescription>
        </CardContent>
        {isLoading && <CardPending>{getPhaseText(phase)}</CardPending>}
      </NavLinkCard>
    );
  } else {
    return (
      <Card>
        <CardCover imageUri={thumbnail} />
        <CardContent>
          <CardName symbol={symbol}>{name}</CardName>
          <CardDescription>{description}</CardDescription>
        </CardContent>
        {isLoading && <CardPending>{getPhaseText(phase)}</CardPending>}
      </Card>
    );
  }
};
