import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { selectors as web3Selectors } from "redux-saga-web3";
import { withRouter } from "react-router-dom";

import {
  actions,
  factoryAddress,
  selectors as factorySelectors,
} from "../contracts/niftyFactory";

import {
  selectCollections,
  selectMyCollections,
  selectPendingCollections,
} from "../selectors/collections";

import AllCollections from "../components/AllCollections";

const mapStateToProps = state => ({
  accounts: web3Selectors.accounts.selectAccounts(state),
  isInitialized: factorySelectors.methods.cacheEvents({ at: factoryAddress })(
    state
  ),
  collections: selectCollections(state),
  myCollections: selectMyCollections(state),
  pendingCollections: selectPendingCollections(state),
  location: state.getIn(["router", "location", "pathname"]),
});

const mapDispatchToProps = dispatch => ({
  cacheAllCreateEvents: eventType =>
    dispatch(
      actions.methods
        .cacheEvents({ at: factoryAddress, fromBlock: 2733600, filter: {} })
        .call(eventType)
    ),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount() {
      if (!this.props.isInitialized) {
        this.props.cacheAllCreateEvents("Created");
      }
    },
  })
)(AllCollections);
