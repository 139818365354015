import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { actions, selectors as niftySelectors } from "../contracts/nifty";
import {
  selectIsOwner,
  selectCollection,
  selectCollectionThumbnail,
  selectPieces,
} from "../selectors/collections";
import { selectPendingPieces } from "../selectors/pieceFactory";

import { withLoading } from "../ui/withLoading";
import Collection from "../components/Collection";

const mapStateToProps = (
  state,
  {
    match: {
      params: { address },
    },
  }
) => ({
  address,
  collection: selectCollection(state, { address }),
  coverUri: selectCollectionThumbnail(state, {
    address,
    thumbnailDimension: "1920",
  }),
  metadata: niftySelectors.methods.getCollectionMetadata({ at: address })(
    state
  ),
  isOwner: selectIsOwner(state, { address }),
  pendingPieces: selectPendingPieces(state, { address }),
  pieces: selectPieces(state, { address }),
  totalSupply: niftySelectors.methods.getCollectionMetadata({ at: address })(
    state
  ),
  location: state.getIn(["router", "location", "pathname"]),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { address: at },
    },
  }
) => ({
  fetchCollection: () => dispatch(actions.methods.getCollection({ at }).call()),
  fetchCollectionMetadata: metadataUri =>
    dispatch(actions.methods.getCollectionMetadata({ at }).call(metadataUri)),
  fetchAllTokens: totalSupply =>
    dispatch(actions.methods.fetchAllTokens({ at }, { totalSupply }).call()),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount() {
      const {
        collection,
        fetchCollection,
        metadata,
        fetchCollectionMetadata,
      } = this.props;
      if (!collection) {
        fetchCollection();
      }
      if (!!collection && !metadata) {
        fetchCollectionMetadata(collection.get("metadataUri"));
      }
    },
  }),
  withLoading(({ collection, totalSupply }) => !collection || !totalSupply),
  lifecycle({
    componentDidMount() {
      const { pieces, totalSupply, fetchAllTokens } = this.props;
      if (!!totalSupply && (!pieces || !pieces.hasOwnProperty(0))) {
        fetchAllTokens(totalSupply ? totalSupply.get("totalSupply") : null);
      }
    },
  })
)(Collection);
