import { createSelector } from "reselect";

import { selectCollection } from "./collections";

const selectState = state => {
  return state.get("nifty");
};

const selectThumbnailDimension = (_, props) => props.thumbnailDimension;
const selectPendingTxnNonce = (_, props) => props.pendingTxnNonce;

export const _selectPendingTxnNonce = createSelector(selectState, state =>
  state.get("pendingTxnNonce")
);

export const selectPendingPieces = createSelector(
  selectCollection,
  collection => (collection ? collection.get("pendingPieces") : null)
);

export const selectPendingPiece = createSelector(
  selectPendingPieces,
  selectPendingTxnNonce,
  (pieces, pendingTxNonce) =>
    pieces.has(pendingTxNonce) ? pieces.get(pendingTxNonce) : null
);

export const selectPendingPiecePhase = createSelector(
  selectPendingPiece,
  piece => (piece && piece.has("phase") ? piece.get("phase") : null)
);

export const selectPendingPieceMetadata = createSelector(
  selectPendingPiece,
  piece => (piece && piece.has("metadata") ? piece.get("metadata") : null)
);

export const selectPendingPieceName = createSelector(
  selectPendingPieceMetadata,
  metadata => (metadata && metadata.has("name") ? metadata.get("name") : null)
);

export const selectPendingPieceDescription = createSelector(
  selectPendingPieceMetadata,
  metadata =>
    metadata && metadata.has("description") ? metadata.get("description") : null
);

export const selectPendingPieceThumbnails = createSelector(
  selectPendingPieceMetadata,
  metadata =>
    metadata && metadata.has("thumbnails") ? metadata.get("thumbnails") : null
);

export const selectPendingPieceImage = createSelector(
  selectPendingPieceMetadata,
  metadata => (metadata && metadata.has("image") ? metadata.get("image") : null)
);

export const selectPendingPieceThumbnail = createSelector(
  selectPendingPieceThumbnails,
  selectThumbnailDimension,
  (thumbnails, dimension = "480") =>
    thumbnails ? thumbnails.getIn([dimension]) : null
);
