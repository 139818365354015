import { combineReducers } from "redux-immutable";
import { reducers as web3Reducers } from "redux-saga-web3";
import { reducer as Nifty } from "../contracts/nifty";
import { reducer as NiftyFactory } from "../contracts/niftyFactory";

import router from "./router";
import nifty from "./nifty";

const reducers = combineReducers({
  ...web3Reducers,
  ...NiftyFactory,
  ...Nifty,
  nifty,
  router,
});

export default reducers;
