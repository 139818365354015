import React from "react";
import styled from "styled-components";
import Spinner from "react-spinkit";
import { NavLink } from "react-router-dom";

import { parseUri } from "../utils/ipfs";

const Card = styled.div`
  margin: 10px;
  color: #484848;
  max-width: 100%;
  position: relative;
  text-decoration: none;
`;

const CardContent = styled.div`
  margin: 17px 20px;
`;

const CardFooter = styled.div`
  border-top: 1px solid #dbdbdb;
  align-items: stretch;
  display: flex;
`;

const CardFooterLink = styled.a`
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  padding: 0.75rem;
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
`;

const CardCover = ({ imageUri }) => {
  if (imageUri) {
    const { scheme, cid } = parseUri(imageUri);
    const imageUrl =
      scheme === "dweb:" ? `http://ipfs.io/ipfs/${cid}` : imageUri;
    return <img src={imageUrl} />;
  } else {
    return <Spinner name="folding-cube" />;
  }
};

const CardName = styled.h4`
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 0;
`;

const CardDescription = styled.span`
  font-weight: 400;
  line-height: 1.25;
  font-size: 15px;
`;

const CardPendingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CardPendingText = styled.div`
  text-align: center;
  color: #fff;
  margin: 25px;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 22px;
`;

const CardPending = ({ children }) => (
  <CardPendingContainer>
    <Spinner name="folding-cube" color="#e3241e" />
    <CardPendingText>{children}</CardPendingText>
  </CardPendingContainer>
);

const CardFooterNavLink = CardFooterLink.withComponent(NavLink);

export {
  Card,
  CardContent,
  CardCover,
  CardDescription,
  CardFooter,
  CardFooterLink,
  CardFooterNavLink,
  CardName,
  CardPending,
};
