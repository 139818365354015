import { compose } from "recompose";
import { connect } from "react-redux";
import { selectors as web3Selectors } from "redux-saga-web3";
import { withRouter } from "react-router-dom";

import { actions } from "../contracts/nifty";
import { _selectPendingTxnNonce } from "../selectors/pieceFactory";

import { selectors as niftySelectors } from "../contracts/nifty";

import MintPieceModal from "../components/MintPieceModal";

const mapStateToProps = (
  state,
  {
    match: {
      params: { address },
    },
  }
) => ({
  accounts: web3Selectors.accounts.selectAccounts(state),
  address,
  pendingTxnNonce: _selectPendingTxnNonce(state),
  collectionType: niftySelectors.methods.getCollectionMetadata({ at: address })(
    state
  ).get("collectionType"),

});

const mapDispatchToProps = dispatch => ({
  onMintPiece: (options, meta, to, name, description, image, ...rest) =>
    dispatch(
      actions.methods
        .premint(options, meta)
        .call(to, name, description, image, ...rest)
    ),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MintPieceModal);
