import Web3Utils from "web3-utils";
import downscale from "downscale";
import IPFS from "ipfs-mini";

export const ipfsWrite = new IPFS({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
});

export const ipfsRead = new IPFS({
  host: "gateway.ipfs.io",
  port: 443,
  protocol: "https",
});

const ipfs = {
  add: content =>
    new Promise((resolve, reject) =>
      ipfsWrite.add(content, (err, res) => (err ? reject(err) : resolve(res)))
    ),
  cat: address =>
    new Promise((resolve, reject) =>
      ipfsRead.cat(address, (err, res) => (err ? reject(err) : resolve(res)))
    ),
};

// export const infuraIPFS = IPFSapi("ipfs.infura.io", 5001, {
//   protocol: "https",
// });
// const ipfs = IPFS("gateway.ipfs.io", 443, { protocol: "https" });

export function parseUri(uri) {
  const [scheme, protocol, cid, ...path] = uri.split("/");
  return {
    scheme,
    protocol,
    cid,
    path,
  };
}

export function readFileToBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onloadend = () => {
      const buffer = Buffer.from(reader.result);
      resolve(buffer);
    };
    reader.readAsArrayBuffer(file);
  });
}

export function uploadImages(image, imageWidths) {
  return Promise.all(
    imageWidths.map(width =>
      downscale(image, width, 0, { returnBlob: 1 })
        .then(resizedImage => readFileToBuffer(resizedImage))
        .then(buffer => ipfs.add(buffer))
        .then(hash => hash)
    )
  );
}

export function getMetadataHash(metadata) {
  return ipfs.add(Buffer.from(JSON.stringify(metadata)));
}

export function formatIpfsUri(hash) {
  return `/ipfs/${hash}`;
}

export function formatFullIpfsUri(hash) {
  return `dweb:/ipfs/${hash}`;
}

export function formatMetadataHash(metadataHash) {
  return Web3Utils.asciiToHex(formatFullIpfsUri(metadataHash));
}

export function constructCollectionMetadata(
  name,
  description,
  hashes,
  IMAGE_WIDTHS,
  owner,
  ownerInfo,
  collectionType,
  media,
  context,
  benefits
) {
  // debugger;
  return {
    name,
    description,
    image: formatFullIpfsUri(hashes[0]),
    thumbnails: IMAGE_WIDTHS.slice(1).reduce(
      (reduction, width, i) => ({
        ...reduction,
        [width]: formatFullIpfsUri(hashes[i + 1]),
      }),
      {}
    ),
    owner,
    ownerInfo,
    collectionType,
    media,
    context,
    benefits,
  };
}

export function constructPieceMetadata(
  name,
  description,
  hashes,
  IMAGE_WIDTHS,
  medium,
  materials,
  creationDate,
  dimensions,
  location
) {
  return {
    name,
    description,
    image: formatFullIpfsUri(hashes[0]),
    thumbnails: IMAGE_WIDTHS.slice(1).reduce(
      (reduction, width, i) => ({
        ...reduction,
        [width]: formatFullIpfsUri(hashes[i + 1]),
      }),
      {}
    ),
    certificate: {
      medium,
      materials,
      creationDate,
      dimensions,
      location,
    },
  };
}

export default ipfs;
