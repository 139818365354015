import React from "react";
import { Toggler } from "smooth-ui";
import Button from "../ui/button";
import {
  CreateCollectionModalContainer,
  MintNiftyModalContainer,
} from "../containers";

export const CreateToggler = ({
  buttonText,
  ModalContainer = CreateCollectionModalContainer,
}) => (
  <Toggler>
    {({ toggled, onToggle }) => (
      <div>
        <Button variant="primary" onClick={() => onToggle(true)}>
          {buttonText}
        </Button>
        <ModalContainer toggled={toggled} onToggle={onToggle} />
      </div>
    )}
  </Toggler>
);

export const CreateCollectionToggler = props => (
  <CreateToggler
    buttonText="Create Collection"
    ModalContainer={CreateCollectionModalContainer}
  />
);

export const MintNiftyToggler = props => (
  <CreateToggler
    buttonText="Mint Piece"
    ModalContainer={MintNiftyModalContainer}
  />
);
