import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  selectPendingPiece,
  selectPendingPieceMetadata,
  selectPendingPiecePhase,
  selectPendingPieceName,
  selectPendingPieceImage,
  selectPendingPieceThumbnail,
  selectPendingPieceDescription,
} from "../selectors/pieceFactory";
import Piece from "../components/Piece";

const mapStateToProps = (state, props) => ({
  piece: selectPendingPiece(state, props),
  address: props.address,
  metadata: selectPendingPieceMetadata(state, props),
  phase: selectPendingPiecePhase(state, props),
  name: selectPendingPieceName(state, props),
  thumbnail: selectPendingPieceThumbnail(state, props),
  description: selectPendingPieceDescription(state, props),
  pendingImage: selectPendingPieceImage(state, props),
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Piece);
