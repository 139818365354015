import { Map, OrderedMap, fromJS } from "immutable";
import Web3Utils from "web3-utils";

import { types as niftyTypes } from "../contracts/nifty";
import { types as factoryTypes } from "../contracts/niftyFactory";

const initialState = Map({
  isInitialized: false,
  isLoading: false,
  pendingTxnNonce: 0,
  pendingContracts: OrderedMap(),
  contracts: OrderedMap(),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case niftyTypes.methods.getCollection.call.SUCCESS: {
      const { payload, meta } = action;
      const {
        options: { at },
      } = meta;
      const contract = Map({
        owner: payload[0],
        name: payload[1],
        symbol: payload[2],
        metadataUri: Web3Utils.hexToAscii(payload[3]),
        totalSupply: payload[4],
        pieces: OrderedMap(),
        pendingPieces: OrderedMap(),
      });

      return state.hasIn(["contracts", at])
        ? state.setIn(
            ["contracts", at],
            state.getIn(["contracts", at]).mergeDeep(contract)
          )
        : state.setIn(["contracts", at], contract);
    }
    case factoryTypes.methods.precreate.call.CALL: {
      const {
        payload: { args, options },
      } = action;
      const [name, symbol, description, file, ownerName, ownerInfo] = args;
      return state.setIn(
        ["pendingContracts", state.get("pendingTxnNonce")],
        Map({
          owner: options.from,
          name,
          symbol,
          description,
          metadata: Map({
            name,
            description,
            image: file.preview,
            ownerName,
            ownerInfo,
          }),
          phase: "UPLOADING",
        })
      );
    }
    case factoryTypes.events.Created.get.GET: {
      return state.set("isInitialized", true);
    }
    case factoryTypes.events.Created.get.SUCCESS: {
      const { payload } = action;
      const contracts = payload.reduce(
        (reduction, { returnValues }) =>
          reduction.set(
            returnValues.token,
            Map({
              address: returnValues.token,
              owner: returnValues.owner,
              name: returnValues.name,
              symbol: returnValues.symbol,
              metadataUri: Web3Utils.hexToAscii(returnValues.metadata),
              pieces: OrderedMap(),
              pendingPieces: OrderedMap(),
            })
          ),
        Map()
      );

      return state.set(
        "contracts",
        state.get("contracts").mergeDeep(contracts)
      );
    }

    case niftyTypes.methods.getCollectionMetadata.call.SUCCESS: {
      const {
        payload,
        meta: {
          options: { at },
        },
      } = action;

      return state.setIn(
        ["contracts", at, "metadata"],
        state.hasIn(["contracts", at, "metadata"])
          ? state
              .getIn(["contracts", at, "metadata"])
              .mergeDeep(fromJS(payload))
          : fromJS(payload)
      );
    }
    case factoryTypes.methods.create.send.SEND: {
      const {
        meta: { pendingTxnNonce },
      } = action;
      return state.setIn(
        ["pendingContracts", pendingTxnNonce, "phase"],
        "SENDING"
      );
    }

    case factoryTypes.methods.create.send.TRANSACTION_HASH: {
      const {
        meta: { pendingTxnNonce },
      } = action;

      return state.setIn(
        ["pendingContracts", pendingTxnNonce, "phase"],
        "MINING"
      );
    }

    case factoryTypes.methods.create.send.RECEIPT: {
      const {
        meta: { pendingTxnNonce },
        payload: { events },
      } = action;
      const {
        returnValues: { metadata, name, owner, symbol, token },
      } = events.Created;

      const prevMetadata = state.getIn([
        "pendingContracts",
        pendingTxnNonce,
        "metadata",
      ]);

      return state.setIn(
        ["contracts", token],
        Map({
          address: token,
          owner,
          name,
          symbol,
          metadata: prevMetadata,
          metadataUri: Web3Utils.hexToAscii(metadata),
          pieces: OrderedMap(),
          pendingPieces: OrderedMap(),
        })
      );
    }

    case niftyTypes.methods.getPieceMetadata.call.SUCCESS: {
      const {
        payload,
        meta: {
          args: [tokenIndex],
          options: { at },
        },
      } = action;

      return state.setIn(
        ["contracts", at, "pieces", tokenIndex, "metadata"],
        fromJS(payload)
      );
    }

    case niftyTypes.methods.premint.call.CALL: {
      const {
        payload: {
          args,
          options: { at },
        },
        meta: { pendingTxnNonce },
      } = action;
      const [
        to,
        name,
        description,
        image,
        medium,
        materials,
        creationDate,
        dimensions,
        location,
      ] = args;
      return state.setIn(
        ["contracts", at, "pendingPieces", pendingTxnNonce],
        Map({
          owner: to,
          name,
          description,
          metadata: Map({
            name,
            description,
            image: image.preview,
            medium,
            materials,
            creationDate,
            dimensions,
            location,
          }),
          phase: "UPLOADING",
        })
      );
    }

    case niftyTypes.methods.mint.send.SEND: {
      const {
        payload: {
          options: { at },
        },
        meta: { pendingTxnNonce },
      } = action;
      return state.setIn(
        ["contracts", at, "pendingPieces", pendingTxnNonce, "phase"],
        "SENDING"
      );
    }

    case niftyTypes.methods.mint.send.TRANSACTION_HASH: {
      const {
        meta: {
          pendingTxnNonce,
          options: { at },
        },
      } = action;

      return state.setIn(
        ["contracts", at, "pendingPieces", pendingTxnNonce, "phase"],
        "MINING"
      );
    }

    case niftyTypes.methods.mint.send.RECEIPT: {
      const {
        meta: {
          pendingTxnNonce,
          options: { at },
        },
        payload: { events },
      } = action;
      const {
        returnValues: { _to, _tokenId },
      } = events.Transfer;

      const prevMetadata = state.getIn([
        "contracts",
        at,
        "pendingPieces",
        pendingTxnNonce,
        "metadata",
      ]);

      return state
        .deleteIn(["contracts", at, "pendingPieces", pendingTxnNonce])
        .setIn(
          ["contracts", at, "pieces", _tokenId],
          Map({
            address: at,
            owner: _to,
            name: prevMetadata.get("name"),
            metadata: prevMetadata,
          })
        );
    }

    default:
      return state;
  }
};
