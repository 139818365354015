import React from "react";
import styled from "styled-components";

import { MintNiftyToggler } from "./CreateToggler";
import { PieceContainer, PiecePendingContainer } from "../containers";
import { Cover, Grid, layout, LoadingMessage } from "../ui";

const StyledCover = styled(Cover)`
  height: 50vh;
  margin-bottom: 50px;
`;

const StyledHeader = styled.div`
  position: absolute;
  right: 35px;
  top: 25px;
`;

function renderPieces(pieces, pendingPieces, address) {
  return pendingPieces
    .map((pieces, i) => (
      <div key={`pending-${i}`}>
        <PiecePendingContainer
          isLoading
          address={address}
          pendingTxnNonce={i}
          tokenIndex={i}
        />
      </div>
    ))
    .valueSeq()
    .reverse()
    .concat(
      pieces
        .map((piece, i) => (
          <div key={i}>
            <PieceContainer tokenIndex={i} address={address} />
          </div>
        ))
        .valueSeq()
        .reverse()
    );
}

const Collection = ({
  address,
  collection,
  coverUri,
  isOwner,
  totalSupply,
  pieces,
  pendingPieces,
  metadata,
}) => {
  if (!coverUri || !totalSupply)
    return <LoadingMessage message="Loading Collection..." />;
  else {
    return (
      <div>
        {isOwner && (
          <StyledHeader>
            <MintNiftyToggler />
          </StyledHeader>
        )}
        <StyledCover
          imageUri={coverUri}
          title={collection.getIn(["metadata", "name"])}
          subtitle={collection.getIn(["metadata", "description"])}
        />
        <Grid
          columns={2}
          columnWidth={480}
          gutterWidth={30}
          gutterHeight={5}
          layout={layout.pinterest}
          duration={0}
        >
          {pieces && renderPieces(pieces, pendingPieces, address)}
        </Grid>
      </div>
    );
  }
};

export default Collection;
