import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { actions, selectors } from "../contracts/nifty";
import {
  selectCollection,
  selectCollectionName,
  selectCollectionDescription,
  selectCollectionSymbol,
  selectCollectionMetadata,
  selectCollectionThumbnail,
} from "../selectors/collections";
import CollectionCard from "../components/CollectionCard";

const mapStateToProps = (state, props) => ({
  collection: selectCollection(state, props),
  metadata: selectCollectionMetadata(state, props),
  name: selectCollectionName(state, props),
  description: selectCollectionDescription(state, props),
  symbol: selectCollectionSymbol(state, props),
  thumbnail: selectCollectionThumbnail(state, props),
});

const mapDispatchToProps = (dispatch, { address }) => ({
  fetchCollectionMetadata: (collection, metadataUri) => {
    // debugger;
    return dispatch(
      actions.methods.getCollectionMetadata({ at: address }).call(metadataUri)
    );
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount() {
      const { collection, metadata, fetchCollectionMetadata } = this.props;
      if (collection.has("metadataUri") && !metadata) {
        fetchCollectionMetadata(collection, collection.get("metadataUri"));
      }
    },
  })
)(CollectionCard);
