import { ModalContent } from "smooth-ui";

const DopeModalContent = ModalContent.extend`
  border-radius: 0;
  border: 1px solid #e9ecef;
  box-shadow: none;

  .sui-modal-footer {
    height: 80px;

    button:not(:first-child) {
      margin-left: 15px;
    }
  }
`;

export { DopeModalContent as ModalContent };
