import { compose } from "recompose";
import { connect } from "react-redux";
import { selectors as web3Selectors } from "redux-saga-web3";

import { actions, factoryAddress } from "../contracts/niftyFactory";
import { _selectPendingTxnNonce } from "../selectors/pieceFactory";

import CreateCollectionModal from "../components/CreateCollectionModal";

const mapStateToProps = state => ({
  accounts: web3Selectors.accounts.selectAccounts(state),
  pendingTxNNonce: _selectPendingTxnNonce(state),
});

const mapDispatchToProps = (dispatch, { accounts }) => ({
  onCreateNifty: (name, ...args) =>
    dispatch(
      actions.methods
        .precreate(
          { at: factoryAddress, from: accounts && accounts.get(0) },
          {}
        )
        .call(name, ...args)
    ),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateCollectionModal);
