import ReduxSagaWeb3EthContract from "redux-saga-web3-eth-contract";
import { takeEvery, put } from "redux-saga/effects";
import Web3 from "web3";
import { NiftyFactory } from "@nifty-supply/contracts";

import { Map, OrderedMap, fromJS } from "immutable";
import { precreate, cacheAllCreateEvents } from "../sagas/niftyFactory";

const CONTRACT_NAME = "NiftyFactory";
const factoryAddress = "0x8576aeE4eFaA3Acb3316a9D4e2795B5145cDf8db";
// const factoryAddress = NiftyFactory.networks["15"].address;

const provider = window.web3
  ? window.web3.currentProvider
  : new Web3.providers.HttpProvider(
      "https://rinkeby.infura.io/0xS8X7c47VxHX0muIHW4"
    );
ReduxSagaWeb3EthContract.setProvider(provider);

const initialState = Map({
  isInitialized: false,
  isLoading: false,
  pendingTxnNonce: 0,
  contracts: OrderedMap(),
});

export const instance = new ReduxSagaWeb3EthContract(
  CONTRACT_NAME,
  NiftyFactory.abi,
  {
    at: factoryAddress,
    provider: new Web3.providers.WebsocketProvider("ws://127.0.0.1:8545"),
  }
);

instance.attachMethod(
  "precreate",
  types =>
    function*() {
      yield takeEvery(types.call.CALL, precreate);
    }
);

instance.attachMethod(
  "cacheEvents",
  types =>
    function*() {
      yield takeEvery(
        instance.types.events.Created.get.SUCCESS,
        cacheAllCreateEvents
      );
      yield takeEvery(types.call.CALL, function*({
        payload: {
          args,
          options: { at, fromBlock, filter },
        },
      }) {
        yield put(
          instance.actions.events[args[0]].get({
            at,
            fromBlock,
          })
        );
        yield put(instance.actions.events[args[0]].subscribe({ at, filter }));
      });
    },
  types => (state = initialState, action) => {
    switch (action.type) {
      case types.call.CALL: {
        return fromJS({ isInitialized: true });
      }
      case types.call.SUCCESS: {
        return fromJS(action.payload);
      }
      default:
        return state;
    }
  }
);
const { contract, types, actions, reducer, selectors, saga } = instance;
export { contract, types, actions, reducer, selectors, saga, factoryAddress };
