import { createSelector } from "reselect";

const selectState = state => {
  return state.get("nifty");
};

export const selectPendingTxnNonce = (_, props) => props.pendingTxnNonce;

export const selectIsInitialized = createSelector(selectState, state =>
  state.get("isInitialized")
);

export const selectIsLoading = createSelector(selectState, state =>
  state.get("isLoading")
);

export const selectPendingCollections = createSelector(selectState, state =>
  state.get("pendingContracts")
);

export const selectPendingCollection = createSelector(
  selectPendingCollections,
  selectPendingTxnNonce,
  (contracts, pendingTxnNonce) =>
    contracts.has(pendingTxnNonce) ? contracts.get(pendingTxnNonce) : null
);

export const selectPendingCollectionMetadata = createSelector(
  selectPendingCollection,
  contract =>
    contract && contract.has("metadata") ? contract.get("metadata") : null
);

export const selectPendingCollectionThumbnail = createSelector(
  selectPendingCollectionMetadata,
  metadata => (metadata ? metadata.get("image") : null)
);

export const selectPendingCollectionName = createSelector(
  selectPendingCollectionMetadata,
  metadata => (metadata && metadata.has("name") ? metadata.get("name") : null)
);

export const selectPendingCollectionDescription = createSelector(
  selectPendingCollectionMetadata,
  metadata =>
    metadata && metadata.has("description") ? metadata.get("description") : null
);

export const selectPendingCollectionSymbol = createSelector(
  selectPendingCollectionMetadata,
  metadata =>
    metadata && metadata.has("symbol") ? metadata.get("symbol") : null
);

export const selectPendingCollectionPhase = createSelector(
  selectPendingCollection,
  collection =>
    collection && collection.has("phase") ? collection.get("phase") : null
);
