import { Button } from "smooth-ui";

const DopeButton = Button.extend`
  border-radius: 0;
  box-shadow: #484848 1px 1px 0, #484848 2px 2px 0, #484848 3px 3px 0;
  transition: margin 0.1s, box-shadow 0.1s;
  font-size: 19px;
  font-weight: 600;

  &:hover {
    box-shadow: #484848 1px 1px 0, #484848 2px 2px 0, #484848 3px 3px 0, #484848 4px 4px 0,
      #484848 5px 5px 0, #484848 6px 6px 0, #484848 7px 7px 0, #484848 8px 8px 0;
  }

  &.sui-button:focus,
  &.sui-button:active {
    box-shadow: #484848 1px 1px 0, #484848 2px 2px 0, #484848 3px 3px 0;
    margin-top: 3px;
    margin-right: -3px;
    margin-left: 3px;
    margin-bottom: -3px;
  }
`;

export default DopeButton;

// box-shadow: #dbdbdb 1px 1px 0, #dbdbdb 2px 2px 0, #dbdbdb 3px 3px 0, #dbdbdb 4px 4px 0, #dbdbdb 5px 5px 0;
//     border: 1px solid #dbdbdb;
//     background-color: #fff;
//
//     box-shadow: #ffffff 1px 1px 0, #ffffff 2px 2px 0, #ffffff 3px 3px 0, #ffffff 4px 4px 0, #dbdbdb 5px 5px 0;
//         border: 1px solid #dbdbdb;
//         background-color: #fff;
