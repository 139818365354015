import styled from "styled-components";
import { CSSGrid, layout, measureItems } from "react-stonecutter";

const StyledGridContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30vh;
`;

const _Grid = measureItems(CSSGrid, {
  // maxWidth: 1920, minPadding: 100,
  measureImages: true,
  background: true,
});

const Grid = styled(_Grid)`
  margin-left: 20px;
  margin-bottom: 30vh;
`;

export { Grid, layout };
