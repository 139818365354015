import { createSelector } from "reselect";
import { selectors } from "redux-saga-web3";

const selectState = state => {
  return state.get("nifty");
};

const selectAddress = (_, props) => props.address;
const selectThumbnailDimension = (_, props) => props.thumbnailDimension;
const selectTokenIndex = (_, props) => props.tokenIndex;
export const selectPendingTxnNonce = (_, props) => props.pendingTxnNonce;

export const selectIsInitialized = createSelector(
  selectState,
  state => state.get("isInitialized")
);

export const selectIsLoading = createSelector(
  selectState,
  state => state.get("isLoading")
);

export const selectCollections = createSelector(
  selectState,
  state => (state.has("contracts") ? state.get("contracts") : null)
);

export const selectMyCollections = createSelector(
  selectCollections,
  selectors.accounts.selectAccounts,
  (contracts, accounts) =>
    contracts.filter(contract => contract.get("owner") === accounts.get(0))
);

export const selectCollection = createSelector(
  selectCollections,
  selectAddress,
  (contracts, address) =>
    contracts.has(address) ? contracts.get(address) : null
);

export const selectPendingCollections = createSelector(
  selectState,
  state => state.get("pendingContracts")
);

export const selectPendingCollection = createSelector(
  selectPendingCollections,
  selectPendingTxnNonce,
  (contracts, pendingTxnNonce) =>
    contracts.has(pendingTxnNonce) ? contracts.get(pendingTxnNonce) : null
);

export const selectPieces = createSelector(
  selectCollection,
  collection => (collection ? collection.get("pieces") : null)
);

export const selectPiece = createSelector(
  selectPieces,
  selectTokenIndex,
  (pieces, tokenIndex) =>
    pieces.has(tokenIndex) ? pieces.get(tokenIndex) : null
);

export const selectPieceMetadata = createSelector(
  selectPiece,
  piece => (piece && piece.has("metadata") ? piece.get("metadata") : null)
);

export const selectPieceName = createSelector(
  selectPieceMetadata,
  metadata => (metadata ? metadata.get("name") : null)
);

export const selectPieceDescription = createSelector(
  selectPieceMetadata,
  metadata => (metadata ? metadata.get("description") : null)
);

export const selectPieceSymbol = createSelector(
  selectPieceMetadata,
  metadata => (metadata ? metadata.get("symbol") : null)
);

export const selectPieceCertificate = createSelector(
  selectPieceMetadata,
  metadata => (metadata.has("certificate") ? metadata.get("certificate") : null)
);

export const selectPieceThumbnails = createSelector(
  selectPieceMetadata,
  metadata =>
    metadata && metadata.has("thumbnails") ? metadata.get("thumbnails") : null
);

export const selectPieceThumbnail = createSelector(
  selectPieceThumbnails,
  selectThumbnailDimension,
  (thumbnails, dimension = "480") =>
    thumbnails ? thumbnails.getIn([dimension]) : null
);

export const selectCollectionMetadata = createSelector(
  selectCollection,
  contract =>
    contract && contract.has("metadata") ? contract.get("metadata") : null
);

export const selectCollectionName = createSelector(
  selectCollectionMetadata,
  metadata => (metadata ? metadata.get("name") : null)
);

export const selectCollectionDescription = createSelector(
  selectCollectionMetadata,
  metadata => (metadata ? metadata.get("description") : null)
);

export const selectCollectionSymbol = createSelector(
  selectCollectionMetadata,
  metadata => (metadata ? metadata.get("symbol") : null)
);

export const selectCollectionArtist = createSelector(
  selectCollectionMetadata,
  metadata => (metadata.has("artist") ? metadata.get("artist") : null)
);

export const selectCollectionArtistInfo = createSelector(
  selectCollectionMetadata,
  metadata => (metadata.has("artistInfo") ? metadata.get("artistInfo") : null)
);

export const selectCollectionThumbnails = createSelector(
  selectCollectionMetadata,
  metadata =>
    metadata && metadata.has("thumbnails") ? metadata.get("thumbnails") : null
);

export const selectCollectionThumbnail = createSelector(
  selectCollectionThumbnails,
  selectThumbnailDimension,
  (thumbnails, dimension = "480") =>
    thumbnails ? thumbnails.getIn([dimension]) : null
);

export const selectOwner = createSelector(
  selectCollection,
  contract => (contract && contract.has("owner") ? contract.get("owner") : null)
);

export const selectIsOwner = createSelector(
  selectOwner,
  selectors.accounts.selectAccounts,
  (owner, accounts) =>
    owner && accounts.has(0) ? owner === accounts.get(0) : null
);

// export const selectTotalSupply = createSelector(
//   selectCollection,
//   contract =>
//     contract && contract.has("totalSupply") ? contract.get("totalSupply") : null
// );
