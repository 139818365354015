import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import Header from "./Header";
import { CreateCollectionToggler } from "./CreateToggler";
import CollectionCardContainer from "../containers/CollectionCardContainer";
import CollectionPendingCardContainer from "../containers/CollectionPendingCardContainer";

const NiftyNavContainer = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  li:first-child {
    margin-right: 20px;
  }
`;

const NiftyNavLink = styled(NavLink)`
  font-size: 19px;
  font-weight: 600;
  color: #484848;
  text-decoration: none;

  &.active {
    box-shadow: #484848 1px 1px 0, #484848 2px 2px 0, #484848 3px 3px 0;
    background-color: #e3241e;
    padding: 6px 10px;
    color: white;
    letter-spacing: 0.07em;
    transition: margin 0.1s, box-shadow 0.1s;

    &:hover {
      box-shadow: #484848 1px 1px 0, #484848 2px 2px 0, #484848 3px 3px 0,
        #484848 4px 4px 0, #484848 5px 5px 0, #484848 6px 6px 0,
        #484848 7px 7px 0, #484848 8px 8px 0;
    }

    &:focus,
    &:active {
      box-shadow: #484848 1px 1px 0, #484848 2px 2px 0, #484848 3px 3px 0;
      margin-bottom: -5px;
      margin-top: 5px;
      margin-right: -5px;
      margin-left: 5px;
    }
  }
`;

const NiftyList = styled.div`
  display: flex;
  margin: 0 40px;
  margin-top: 60px;
  flex-wrap: wrap;
`;

function renderCollections(collections, pendingCollections) {
  return pendingCollections
    .map((collection, i) => {
      return (
        <CollectionPendingCardContainer key={i} isLoading pendingTxnNonce={i} />
      );
    })
    .valueSeq()
    .reverse()
    .concat(
      collections
        .map((collection, address) => (
          <CollectionCardContainer key={address} address={address} />
        ))
        .valueSeq()
        .reverse()
    );
}

const AllCollections = ({
  accounts,
  collections,
  myCollections,
  pendingCollections,
}) => (
  <div>
    <Header right={<CreateCollectionToggler />} />
    <NiftyNavContainer>
      <li>
        <NiftyNavLink to="/" exact>
          All Collections
        </NiftyNavLink>
      </li>
      <li>
        <NiftyNavLink to="/mine" exact>
          My Collections
        </NiftyNavLink>
      </li>
    </NiftyNavContainer>
    <NiftyList>
      <Switch>
        <Route
          path="/"
          exact
          render={() => renderCollections(collections, pendingCollections)}
        />
        <Route
          path="/mine"
          exact
          render={() => renderCollections(myCollections, pendingCollections)}
        />
      </Switch>
    </NiftyList>
  </div>
);

export default AllCollections;
