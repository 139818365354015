import React from "react";
import styled from "styled-components";
import Spinner from "react-spinkit";

import { parseUri } from "../utils/ipfs";

const CoverStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const CoverTitle = styled.h1`
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.75);
`;

const CoverSubtitle = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.75);
`;

const Cover = ({ className, imageUri, title, subtitle }) => {
  if (imageUri) {
    const { scheme, cid } = parseUri(imageUri);
    const imageUrl =
      scheme === "dweb:" ? `https://ipfs.io/ipfs/${cid}` : imageUri;
    // debugger;
    return (
      <CoverStyle
        className={className}
        style={{
          backgroundImage: `url("${imageUrl}")`,
        }}
      >
        <CoverTitle>{title}</CoverTitle>
        <CoverSubtitle>{subtitle}</CoverSubtitle>
      </CoverStyle>
    );
  } else {
    return (
      <CoverStyle className={className}>
        <Spinner name="folding-cube" />
      </CoverStyle>
    );
  }
};

export { Cover };
