import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { actions } from "../contracts/nifty";

import {
  selectPieceMetadata,
  selectPieceName,
  selectPieceThumbnail,
  selectPieceDescription,
  selectPieceCertificate,
} from "../selectors/collections";
import Piece from "../components/Piece";

const mapStateToProps = (state, props) => ({
  address: props.address,
  metadata: selectPieceMetadata(state, props),
  name: selectPieceName(state, props),
  thumbnail: selectPieceThumbnail(state, props),
  description: selectPieceDescription(state, props),
  certificate: selectPieceCertificate(state, props),
});

const mapDispatchToProps = (dispatch, { address: at }) => ({
  tokenURI: tokenIndex =>
    dispatch(actions.methods.tokenURI({ at }).call(tokenIndex)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount() {
      const { tokenURI, tokenIndex, metadata } = this.props;
      if (!metadata) {
        tokenURI(tokenIndex);
      }
    },
  })
)(Piece);
