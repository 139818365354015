import ReduxSagaWeb3EthContract from "redux-saga-web3-eth-contract";
import { Map, OrderedMap, fromJS, List } from "immutable";
import Web3Utils from "web3-utils";
import { takeEvery } from "redux-saga/effects";
import Web3 from "web3";
import { Nifty } from "@nifty-supply/contracts";

import {
  _fetchAllTokens,
  fetchAllTokens,
  getCollectionMetadata,
  getPieceMetadata,
  premint,
  tokenByIndex,
  cacheAllCreateEvents,
} from "../sagas/nifty";

import { types as factoryTypes } from "./niftyFactory";
import { relayGetCollection } from "../sagas/niftyFactory";

const CONTRACT_NAME = "Nifty";

const provider = window.web3
  ? window.web3.currentProvider
  : new Web3.providers.HttpProvider(
      "https://rinkeby.infura.io/0xS8X7c47VxHX0muIHW4"
    );
ReduxSagaWeb3EthContract.setProvider(provider);

export const instance = new ReduxSagaWeb3EthContract(CONTRACT_NAME, Nifty.abi, {
  at: Nifty.networks["15"].address,
  provider: new Web3.providers.WebsocketProvider("ws://127.0.0.1:8545"),
});

const initialState = Map({
  isInitialized: false,
});

instance.attachMethod(
  "getCollectionMetadata",
  types =>
    function*() {
      yield takeEvery(types.call.CALL, getCollectionMetadata);
      yield takeEvery(
        instance.types.methods.getCollection.call.SUCCESS,
        getCollectionMetadata
      );
    },
  types => (state, action) => {
    switch (action.type) {
      case types.call.SUCCESS: {
        const { payload } = action;

        const result = fromJS({ ...payload, isInitialized: true });
        return result;
      }
      case instance.types.methods.getCollection.call.SUCCESS: {
        const { payload } = action;
        const contract = Map({
          owner: payload[0],
          name: payload[1],
          symbol: payload[2],
          metadataUri: Web3Utils.hexToAscii(payload[3]),
          totalSupply: payload[4],
          pieces: OrderedMap(),
          pendingPieces: OrderedMap(),
        });
        return fromJS(contract);
      }
      default:
        return initialState;
    }
  }
);

instance.attachMethod(
  "fetchAllTokens",
  types =>
    function*() {
      yield takeEvery(types.call.CALL, fetchAllTokens);
      yield takeEvery(
        instance.types.methods.totalSupply.call.SUCCESS,
        _fetchAllTokens
      );
      yield takeEvery(
        instance.types.methods.tokenByIndex.call.CALL,
        tokenByIndex
      );
    },
  types => (state = initialState, action) => {
    switch (action.type) {
      case types.call.CALL: {
        return fromJS({ isInitialized: true });
      }
      case types.call.SUCCESS: {
        return fromJS({
          pieces: List(...action.payload),
        });
      }
      default:
        return state;
    }
  }
);

instance.attachMethod(
  "getPieceMetadata",
  types =>
    function*() {
      yield takeEvery(types.call.CALL, getPieceMetadata);
      yield takeEvery(
        instance.types.methods.tokenURI.call.SUCCESS,
        getPieceMetadata
      );
    },
  types => (state, action) => {
    switch (action.type) {
      case types.call.SUCCESS: {
        const {
          payload,
          meta: {
            args: [tokenIndex],
          },
        } = action;
        return state.has()
          ? state.get().mergeDeep(Map({ [tokenIndex]: fromJS(payload) }))
          : Map({ [tokenIndex]: fromJS(payload) });
      }
      default:
        return OrderedMap();
    }
  }
);

instance.attachMethod(
  "premint",
  types =>
    function*() {
      yield takeEvery(types.call.CALL, premint);
    },
  types => (state, action) => {
    switch (action.type) {
      case types.call.CALL: {
        const {
          payload: { args },
          meta: { pendingTxnNonce },
        } = action;
        const [
          to,
          name,
          description,
          image,
          medium,
          materials,
          creationDate,
          dimensions,
          location,
        ] = args;
        return fromJS({
          [pendingTxnNonce]: {
            owner: to,
            name,
            description,
            metadata: Map({
              name,
              description,
              image: image.preview,
              medium,
              materials,
              creationDate,
              dimensions,
              location,
            }),
            phase: "UPLOADING",
          },
        });
      }
      default:
        return OrderedMap();
    }
  }
);

const { contract, types, actions, reducer, selectors, saga } = instance;
export { contract, types, actions, reducer, selectors, saga };
