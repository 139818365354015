import React, { Component, Fragment } from "react";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { actions } from "redux-saga-web3";
import styled from "styled-components";

import NetworkStatus from "react-redux-saga-web3/lib/components/NetworkStatus";
import { LoadingMessage } from "./ui/withLoading";
import AllCollectionsContainer from "./containers/AllCollectionsContainer";
import CollectionContainer from "./containers/CollectionContainer";

const StyledNetworkStatus = styled(NetworkStatus)`
  position: absolute;
  top: 10px;
  right: 20px;
`;

class App extends Component {
  render() {
    const { network, accounts } = this.props;
    console.log("network: ", network);
    return (
      <Fragment>
        <StyledNetworkStatus
          networkId={network.get("id")}
          address={accounts[0]}
        />
        {network.get("id") !== 4 ? (
          <LoadingMessage message="Please switch to the Rinkeby Ethereum Network" />
        ) : (
          <Switch>
            <Route
              path="/collections/:address"
              exact
              component={CollectionContainer}
            />
            <Route path="/" component={AllCollectionsContainer} />
          </Switch>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  init() {
    dispatch(actions.init.init());
    dispatch(actions.network.getId());
    dispatch(actions.accounts.getRequest());
    dispatch(actions.blocks.subscribeNewHeaders());
  },
});

const mapStateToProps = state => ({
  accounts: state.get("accounts"),
  network: state.get("network"),
  router: state.get("router"),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount() {
      this.props.init();
    },
  })
)(App);
