import React from "react";
import {
  Input,
  Select,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Textarea,
  Typography,
} from "smooth-ui";
import { Form, Field } from "react-final-form";
import Dropzone from "react-dropzone";

import AdaptedFormGroup from "./FormGroup";
import ImagePlaceholder from "./ImagePlaceholder";
import Button from "../ui/button";
import { ModalContent } from "../ui/modal";

const required = value => (value ? undefined : "Required");

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const CreateCollectionModal = ({ onCreateNifty, toggled, onToggle }) => (
  <Form
    onSubmit={({
      name,
      symbol,
      description,
      files,
      owner,
      ownerInfo,
      collectionType,
      media,
      context,
      benefits,
    }) => {
      onCreateNifty(
        name,
        symbol,
        description,
        files[0],
        owner,
        ownerInfo,
        collectionType,
        media,
        context,
        benefits
      );
      onToggle(false);
    }}
    validateOnBlur={true}
    render={({ handleSubmit, pristine, invalid, form }) => (
      <Modal opened={toggled} onClose={() => onToggle(false)}>
        <ModalDialog>
          <ModalContent>
            <form onSubmit={handleSubmit}>
              <ModalHeader>
                <Typography variant="h5" margin={false}>
                  Create a collection
                </Typography>
              </ModalHeader>
              <ModalBody>
                <Field name={`files`}>
                  {fieldprops => (
                    <div>
                      <Label>Collection Cover Image</Label>
                      <Dropzone
                        onDrop={(files, e) => {
                          form.change(`files`, files);
                          form.blur(`files`);
                        }}
                        multiple={false}
                        style={{
                          border: "none",
                          marginBottom: "6px",
                        }}
                      >
                        {fieldprops.input.value.length > 0 ? (
                          <img
                            style={{
                              width: "462px",
                              height: "309px",
                            }}
                            src={fieldprops.input.value[0].preview}
                          />
                        ) : (
                          <ImagePlaceholder />
                        )}
                      </Dropzone>
                    </div>
                  )}
                </Field>
                <AdaptedFormGroup
                  label="Name"
                  name="name"
                  placeholder="Artonomous"
                  component={Input}
                  validate={required}
                  adapt
                />
                <AdaptedFormGroup
                  label="Symbol"
                  name="symbol"
                  placeholder="ART"
                  component={Input}
                  validate={required}
                  adapt
                />
                <AdaptedFormGroup
                  label="Description"
                  name="description"
                  placeholder="A description of your collection"
                  component={Textarea}
                  validate={required}
                  adapt
                />
                <AdaptedFormGroup
                  label="Owner"
                  name="owner"
                  placeholder="Leonardo DaVinci"
                  component={Input}
                  validate={required}
                  adapt
                />
                <AdaptedFormGroup
                  label="Owner Profile"
                  name="ownerInfo"
                  placeholder="Owners profile"
                  component={Textarea}
                  validate={required}
                  adapt
                />
                <AdaptedFormGroup
                  label="Collection Type"
                  name="collectionType"
                  placeholder="Select Type"
                  component={Select}
                  validate={required}
                  options={[
                    { label: "Visual Art", value: "art" },
                    { label: "Avatars", value: "avatars" },
                    { label: "Game Items", value: "game" },
                  ]}
                  adapt
                />
                <Condition when="collectionType" is="art">
                  <div>
                    <AdaptedFormGroup
                      label="Media"
                      name="media"
                      placeholder="Oil on Canvas"
                      component={Input}
                      validate={required}
                      adapt
                    />
                  </div>
                </Condition>
                <Condition when="collectionType" is="game">
                  <div>
                    <AdaptedFormGroup
                      label="Suggested in Game Usage"
                      name="benefits"
                      placeholder="These items grant avatars the ability to defend themselves, attack, and cast spells"
                      component={Textarea}
                      validate={required}
                      adapt
                    />
                  </div>
                </Condition>
                <Condition when="collectionType" is="avatars">
                  <div>
                    <AdaptedFormGroup
                      label="Context"
                      name="context"
                      placeholder="These avatars are meant to be used as champions in massively multiplayer games"
                      component={Textarea}
                      validate={required}
                      adapt
                    />
                  </div>
                </Condition>
              </ModalBody>
              <ModalFooter>
                <Button variant="primary" type="submit" disabled={pristine}>
                  Create
                </Button>
                <Button variant="secondary" onClick={() => onToggle(false)}>
                  Close
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </ModalDialog>
      </Modal>
    )}
  />
);
//<TODO: add custom fields that can be added to pieces in a collection

export default CreateCollectionModal;
