import React, { Fragment } from "react";
import { branch, renderComponent } from "recompose";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { Box } from "smooth-ui";

const Container = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  margin-top: -30px;
`;

const Message = styled.h3`
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const LoadingMessage = ({ message }) => (
  <Container alignItems="center" justifyContent="center" direction="column">
    <Title>Nifty.Supply</Title>
    {message && <Message>{message}</Message>}
    <Spinner name="folding-cube" />
  </Container>
);

export const withLoading = isLoading => {
  return branch(isLoading, renderComponent(LoadingMessage));
};
