import Web3Utils from "web3-utils";
import { call, put, select, all } from "redux-saga/effects";
import { Range } from "immutable";

import { selectPendingTxnNonce } from "../selectors/collections";
import ipfs, {
  constructPieceMetadata,
  parseUri,
  uploadImages,
  getMetadataHash,
  formatMetadataHash,
} from "../utils/ipfs";
import { IMAGE_WIDTHS } from "../utils/constants";

import {
  actions,
  types,
  selectors as niftySelectors,
} from "../contracts/nifty";
import { selectors as factorySelectors } from "../contracts/niftyFactory";

export function* tokenByIndex(tokenIndex, options) {
  const index = tokenIndex;
  yield put(actions.methods.tokenURI(options).call(index));
}

export function* getPieceMetadata({ payload, meta = {} }) {
  const args = payload.args || meta.args;
  const options = payload.options || meta.options;

  const metadataURI = Web3Utils.hexToAscii(payload);
  const { cid: metadataCID } = parseUri(metadataURI);

  const data = yield call(ipfs.cat, metadataCID);
  const metadata = JSON.parse(data);

  yield put({
    type: types.methods.getPieceMetadata.call.SUCCESS,
    payload: metadata,
    meta: {
      ...meta,
      args,
      options,
    },
  });
}

export function* getCollectionMetadata({ type, payload, meta = {} }) {
  const args = payload.args || meta.args;
  const options = payload.options || meta.options;

  const metadataUri =
    type === types.methods.getCollection.call.SUCCESS
      ? Web3Utils.hexToAscii(payload[3])
      : args[0];
  const { cid: metadataCID } = parseUri(metadataUri);

  const data = yield call(ipfs.cat, metadataCID);
  const contractData = yield select(
    niftySelectors.methods.getCollection({ at: options.at })
  );
  try {
    const metadata = JSON.parse(data);
    yield put({
      type: types.methods.getCollectionMetadata.call.SUCCESS,
      payload: {
        ...metadata,
        symbol:
          contractData && contractData.get("value")
            ? contractData.get("value")[2]
            : null,
        totalSupply:
          payload[4] || (contractData && contractData.get("value"))
            ? contractData.get("value")[4]
            : null,
      },
      meta: {
        ...meta,
        args,
        options,
        calledBy: type,
      },
    });
  } catch (err) {
    console.log("error my dude: ", err);
  }
}

export function* premint({
  meta: { pendingTxnNonce },
  payload: { args, options },
}) {
  const [to, name, description, image, ...rest] = args;

  let hashes;
  if (image) hashes = yield uploadImages(image, IMAGE_WIDTHS);

  const metadata = constructPieceMetadata(
    name,
    description,
    hashes,
    IMAGE_WIDTHS,
    ...rest
  );

  const [metadataHash] = yield call(getMetadataHash, metadata);
  const encodedMetadataHash = formatMetadataHash(metadataHash);
  const tokenUri = encodedMetadataHash;

  yield put(
    actions.methods.mint(options, { pendingTxnNonce }).send(to, tokenUri)
  );
}

export function* _fetchAllTokens({ payload, meta }) {
  const options = payload.options || meta.options;
  const totalSupply = payload;
  yield put(
    actions.methods.fetchAllTokens({ at: options.at }, { totalSupply }).call()
  );
}

export function* fetchAllTokens({ payload, meta }) {
  const args = payload.args || meta.args;
  const options = payload.options || meta.options;

  const address = options.at || args[0];
  const totalSupply = meta.totalSupply;

  const range = Range(0, Number(totalSupply));
  // debugger;
  yield all(
    range
      .map(function*(tokenIndex) {
        yield put(
          actions.methods.tokenURI({ at: address }).call(Number(tokenIndex))
        );
      })
      .toJS()
  );
}
