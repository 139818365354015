import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import promiseMiddleware from "redux-promise";
import { createLogger } from "redux-logger";
import Web3 from "web3";
import { Iterable } from "immutable";

import reducers from "./reducers";
import sagas from "./sagas";

// Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const web3 = new Web3();
web3.setProvider(
  window.web3
    ? window.web3.currentProvider
    : new Web3.providers.HttpProvider(
        "https://rinkeby.infura.io/0xS8X7c47VxHX0muIHW4"
      )
);

export default history => {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      web3,
    },
  });
  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware,
    promiseMiddleware,
  ];
  if (process.env.NODE_ENV === "development") {
    const logger = createLogger({
      stateTransformer: state => {
        if (Iterable.isIterable(state)) return state.toJS();
        else return state;
      },
    });
    middlewares.push(logger);
  }
  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  return store;
};
