import React, { Component } from "react";
import { Field } from "react-final-form";
import { FormGroup, Label } from "smooth-ui";

const adaptComponent = Component => ({ input, meta: { valid }, ...rest }) => (
  <Component {...input} {...rest} valid={valid} />
);

class AdaptedFormGroup extends Component {
  constructor(props) {
    super(props);

    this.component = props.adapt
      ? adaptComponent(props.component)
      : props.component;
  }

  render() {
    const {
      adapt,
      label,
      name,
      placeholder = "",
      component,
      validate,
      ...rest
    } = this.props;
    return (
      <FormGroup>
        {label && <Label htmlFor={`${name}-field-group`}>{label}</Label>}
        <Field
          name={name}
          id={`${name}-field-group`}
          component={this.component}
          control
          placeholder={placeholder}
          validate={validate}
          {...rest}
        />
      </FormGroup>
    );
  }
}

export default AdaptedFormGroup;
