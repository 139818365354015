import { call, put, select } from "redux-saga/effects";

import { _selectPendingTxnNonce } from "../selectors/pieceFactory";
import Web3Utils from "web3-utils";
import {
  constructCollectionMetadata,
  formatMetadataHash,
  getMetadataHash,
  uploadImages,
  infuraIPFS,
} from "../utils/ipfs";
import { IMAGE_WIDTHS } from "../utils/constants";

import { actions as niftyActions } from "../contracts/nifty";
import {
  actions as factoryActions,
  types as factoryTypes,
} from "../contracts/niftyFactory";

export function* precreate({ payload: { args, options } }) {
  const [
    name,
    symbol,
    description,
    cover,
    owner,
    ownerInfo,
    collectionType,
    ...rest
  ] = args;
  const hashes = yield uploadImages(cover, IMAGE_WIDTHS);
  const pendingTxnNonce = yield select(_selectPendingTxnNonce);

  const metadata = constructCollectionMetadata(
    name,
    description,
    hashes,
    IMAGE_WIDTHS,
    ...rest
  );

  const metadataHash = yield call(getMetadataHash, metadata);
  const encodedMetadataHash = formatMetadataHash(metadataHash);

  yield put(
    factoryActions.methods
      .create(options, { pendingTxnNonce })
      .send(name, symbol, encodedMetadataHash)
  );
}

export function* cacheAllCreateEvents({ type, payload, meta }) {
  if (meta.event === "Created") {
    let contracts = {};
    payload.forEach(({ returnValues }) => {
      contracts[returnValues.token] = {
        address: returnValues.token,
        owner: returnValues.owner,
        name: returnValues.name,
        symbol: returnValues.symbol,
        metadataUri: Web3Utils.hexToAscii(returnValues.metadata),
        pieces: {},
        pendingPieces: {},
      };
    });

    yield put({
      type: factoryTypes.methods.cacheEvents.call.SUCCESS,
      payload: { contracts },
      meta: { ...meta, calledBy: type, options: { at: meta.options.at } },
    });
    yield payload.map(collection =>
      put(
        niftyActions.methods
          .getCollection({ at: collection.returnValues[1] })
          .call()
      )
    );
  } else {
    console.log("Why are we here?");
  }
}
