import React from "react";
import {
  Label,
  Input,
  Textarea,
  Modal,
  ModalBody,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
} from "smooth-ui";
import { Form, Field } from "react-final-form";
import Dropzone from "react-dropzone";

import AdaptedFormGroup from "./FormGroup";
import ImagePlaceholder from "./ImagePlaceholder";
import Button from "../ui/button";
import { ModalContent } from "../ui/modal";

const required = value => (value ? undefined : "Required");

const collectionTypeSpecificFields = [
  <AdaptedFormGroup
    key="0"
    name="medium"
    label="Medium"
    placeholder="Art Medium"
    component={Input}
    adapt
    validate={required}
    collectionType="art"
  />,
  <AdaptedFormGroup
    key="1"
    name="materials"
    label="Materials"
    placeholder="Piece Materials"
    component={Input}
    adapt
    validate={required}
    collectionType="art"
  />,
  <AdaptedFormGroup
    key="2"
    name="creationDate"
    label="Creation Date"
    placeholder="Piece Creation Date"
    component={Input}
    adapt
    validate={required}
    collectionType="art"
  />,
  <AdaptedFormGroup
    key="3"
    name="dimensions"
    label="Dimensions"
    placeholder="Piece Dimensions"
    component={Input}
    adapt
    validate={required}
    collectionType="art"
  />,
  <AdaptedFormGroup
    key="4"
    name="location"
    label="Location"
    placeholder="Creation Location"
    component={Input}
    adapt
    validate={required}
    collectionType="art"
  />,
  <AdaptedFormGroup
    key="5"
    name="pieceCustomMetadata"
    label="Metadata"
    placeholder="health: 100, armour: 50, attack: 5"
    component={Input}
    adapt
    collectionType="game"
  />,
  <AdaptedFormGroup
    key="6"
    name="pieceCustomMetadata"
    label="Metadata"
    placeholder="health: 100, armour: 50, attack: 5"
    component={Input}
    adapt
    collectionType="avatars"
  />,
  // "isOriginal",
  // "copyrights",
];

const MintPieceModal = ({
  accounts,
  onMintPiece,
  toggled,
  onToggle,
  address,
  pendingTxnNonce,
  collectionType
}) => (
  <Form
    onSubmit={({
      to,
      name,
      description,
      files,
      medium,
      materials,
      creationDate,
      dimensions,
      location,
    }) => {
      onMintPiece(
        { at: address },
        { pendingTxnNonce },
        accounts.get(0),
        name,
        description,
        files[0],
        medium,
        materials,
        creationDate,
        dimensions,
        location
      );
      onToggle(false);
    }}

    validateOnBlur={true}
    render={({ handleSubmit, pristine, invalid, form }) => (
      <Modal opened={toggled} onClose={() => onToggle(false)}>
        <ModalDialog>
          <ModalContent>
            <form onSubmit={handleSubmit}>
              <ModalHeader>
                <Typography variant="h5" margin={false}>
                  Mint a new Piece
                </Typography>
              </ModalHeader>
              <ModalBody>
                <Field name={`files`}>
                  {fieldprops => (
                    <div>
                      <Label>Image</Label>
                      <Dropzone
                        onDrop={(files, e) => {
                          form.change(`files`, files);
                          form.blur(`files`);
                        }}
                        multiple={false}
                        style={{
                          border: "none",
                          marginBottom: "6px",
                        }}
                      >
                        {fieldprops.input.value.length > 0 ? (
                          <img
                            style={{
                              width: "462px",
                              height: "309px",
                            }}
                            src={fieldprops.input.value[0].preview}
                          />
                        ) : (
                          <ImagePlaceholder />
                        )}
                      </Dropzone>
                    </div>
                  )}
                </Field>
                <AdaptedFormGroup
                  label="Name"
                  name="name"
                  placeholder="Artonomous"
                  component={Input}
                  validate={required}
                  adapt
                />
                <AdaptedFormGroup
                  label="Description"
                  name="description"
                  placeholder="A description of your piece"
                  component={Textarea}
                  validate={required}
                  adapt
                />
              {collectionTypeSpecificFields.filter(field => field.props.collectionType == collectionType)}
              </ModalBody>
              <ModalFooter>
                <Button variant="primary" type="submit" disabled={pristine}>
                  Mint
                </Button>
                <Button variant="secondary" onClick={() => onToggle(false)}>
                  Close
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </ModalDialog>
      </Modal>
    )}
  />
);

export default MintPieceModal;
